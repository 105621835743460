import { css } from "styled-components";

const fontsPath = "../assets/fonts/open-sans-condensed/";

export const OpenSansCondensedFonts = css`
    @font-face {
        font-family: "Open Sans Condensed";
        src: url("${fontsPath}OpenSansCondensed-Light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Open Sans Condensed";
        src: url("${fontsPath}OpenSansCondensed-Regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Open Sans Condensed";
        src: url("${fontsPath}OpenSansCondensed-Medium.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Open Sans Condensed";
        src: url("${fontsPath}OpenSansCondensed-Bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }
`;

import { ApolloClient, gql } from "@apollo/client";
import {
    GetCountries_countries,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetFreshAffilateLinkDomain,
    GetFreshAffilateLinkDomainVariables,
    PartnerAccount_authorized_partnerAccount,
    PartnerInformation_authorized_user,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { PopupType } from "./popup-id";
import { getPopupsConfig } from "./get-popups-config";

export class ReferencePopupRepository {
    private static GET_FRESH_AFFILIATE_LINK_DOMAIN = gql`
        query GetFreshAffilateLinkDomain($domainInfo: DomainInfo!) {
            authorized {
                partner {
                    freshAffilateLinkDomain(domainInfo: $domainInfo)
                }
            }
        }
    `;
    private __: TFunction;
    private client: ApolloClient<unknown>;
    private partnerAccountData: PartnerAccount_authorized_partnerAccount["mainAccountData"];
    private lang: string | null;

    constructor(
        __: TFunction,
        client: ApolloClient<unknown>,
        partnerAccountData?: PartnerAccount_authorized_partnerAccount["mainAccountData"],
        lang?: string,
    ) {
        this.__ = __;
        this.client = client;
        this.partnerAccountData = partnerAccountData || null;
        this.lang = lang || null;
    }

    public getLoadingPopupData(
        partner: PartnerInformation_authorized_user,
        currencies: GetCurrencies_authorized_partnerAndManager_data_currencies[],
        currentGeoCountryCode: string | undefined,
        countryCodes: Map<GetCountries_countries["id"], string>,
    ) {
        const popupData = getPopupsConfig(
            currentGeoCountryCode,
            this.partnerAccountData,
            countryCodes,
            this.__,
            this.lang,
        );
        const loadingPopupData = [];
        for (const pData of popupData) {
            loadingPopupData.push(
                (async () => {
                    if (
                        pData.type === PopupType.BlockReference &&
                        pData.currency
                    ) {
                        const isCurrencyMatch = currencies.some(
                            currency => currency.name === pData.currency?.name,
                        );
                        if (isCurrencyMatch) {
                            // предполагается, что у этого партнера есть эта валюта
                            // т.к. как раз ему включили попап - то адрес для него можно получить
                            // если нет, то будет брошено исключение
                            pData.linkInPopup =
                                await this.getFreshAffilateLinkDomain(
                                    pData.currency,
                                    partner,
                                );
                        }
                    }

                    return pData;
                })(),
            );
        }

        return loadingPopupData;
    }

    private async getFreshAffilateLinkDomain(
        currency: GetCurrencies_authorized_partnerAndManager_data_currencies,
        partner: PartnerInformation_authorized_user,
    ) {
        const variables: GetFreshAffilateLinkDomainVariables = {
            domainInfo: {
                adAgentId: partner.adAgentId,
                affiliateId: partner.affiliateId,
                currency: currency.id,
                materialGroupId: partner.materialGroupId,
            },
        };
        const response = await this.client.query<
            GetFreshAffilateLinkDomain,
            GetFreshAffilateLinkDomainVariables
        >({
            query: ReferencePopupRepository.GET_FRESH_AFFILIATE_LINK_DOMAIN,
            variables,
        });

        return response.data.authorized.partner.freshAffilateLinkDomain;
    }
}

import { Helmet, HelmetsOpenedVisor } from "@webx/react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { useApolloClient } from "@apollo/client";
import { SignInStage } from "hooks/sign-in/stage/use-stage";

interface IProps {
    stage: SignInStage;
    setStage: (stage: SignInStage) => void;
    title?: string;
    description?: string;
}

export const SignInHelmet = ({
    stage,
    setStage,
    title,
    description,
}: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [__] = useTranslation();
    const client = useApolloClient();
    const defaultTitle = __("Войти");
    const defaultDescription = format(
        __(
            "Партнерская программа {PartnerCompanyName}. Зарабатывай на спорте вместе с {CompanyName} по реферальной программе до {Comission}.",
        ),
        {
            PartnerCompanyName:
                partnersGeneralInformation.partnerProgramCompanyName,
            CompanyName:
                partnersGeneralInformation.agentSiteInformation.companyName,
            Comission: partnersGeneralInformation.landingMainPage?.comission,
        },
    );

    const getSeoScripts = () => {
        if (stage === SignInStage.SUCCESS) {
            return partnersGeneralInformation.seoScriptsAndTags
                .successfullLoginScript;
        }

        if (
            stage === SignInStage.SIMPLE_SIGN_IN_ERROR ||
            stage === SignInStage.TWO_FA_ERROR
        ) {
            return partnersGeneralInformation.seoScriptsAndTags
                .unsuccessfullLoginScript;
        }

        return "";
    };

    return (
        <Helmet
            onChangeClientState={async () => {
                if (stage === SignInStage.SUCCESS) {
                    await client.resetStore();
                    setStage(SignInStage.FINISH);
                }
            }}
        >
            <title>{!title ? defaultTitle : `${title}`}</title>
            <meta
                name="description"
                content={!description ? defaultDescription : `${description}`}
            />
            <HelmetsOpenedVisor>{getSeoScripts()}</HelmetsOpenedVisor>
        </Helmet>
    );
};

import {
    PartnerAccount,
    PartnerInformation,
} from "gql/types/operation-result-types";
import { useCurrency } from "hooks/query/use-currency";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { NotificationPopup } from "../notification-popup";
import PARTNER_INFORMATION from "./partnerInformationQuery.gql";
import { PopupType } from "./popup-id";
import { PopupData } from "./popups-data";
import { ReferencePopupRepository } from "./reference-popup-repository";
import { BlockReferenceContent } from "./block-reference";
import loadable from "@loadable/component";
import { BannerContent } from "./banner";
import { useSelector } from "react-redux";
import { settingsSelectors } from "services/redux/root/settings/selectors";
import PARTNER_ACCOUNT from "views/partner/main/profile/partner-account.gql";
import { usePopupHistories } from "./hooks/use-popup-history";
import { TelegramPopupData } from "./telegram/telegram-popup-data";
import { InfoBlockPopupData } from "./info-block/info-block-popup-data";
import { BannerPopupData } from "./banner/banner-popup-data";
import { useCountries } from "hooks/query/use-countries";
import { useLanguage } from "components/language-provider";

const TelegramContent = loadable(() => import("./telegram"), {
    resolveComponent: components => components.TelegramContent,
});

const ConfirmEmailContent = loadable(() => import("./confirm-email"), {
    resolveComponent: components => components.ConfirmEmailPopup,
});

const InfoBlockContent = loadable(() => import("./info-block"), {
    resolveComponent: components => components.InfoBlockPopup,
});

const CustomBlockContent = loadable(
    () => import("./custom-banners/custom-banner-wrapper"),
    {
        resolveComponent: components => components.CustomBannerWrapper,
    },
);

export const NotificationPopupsList = () => {
    const apolloClient = useApolloClient();
    const { data: partnerInformationData, loading: partnerInformationLoading } =
        useQuery<PartnerInformation>(PARTNER_INFORMATION, {
            ssr: true,
        });
    const partnerAccountData = useQuery<PartnerAccount>(PARTNER_ACCOUNT, {
        ssr: true,
    });
    const mainAccountData =
        partnerAccountData?.data?.authorized.partnerAccount.mainAccountData;

    const { data: currenciesData, loading: currenciesLoading } = useCurrency();
    const { countryCodes, loading: countriesLoading } = useCountries();

    const [__] = useTranslation();
    const [loadedPopups, setLoadedPopups] = useState<PopupData[] | undefined>();
    const { geoCountryCode } = useSelector(state =>
        settingsSelectors.getSettings(state),
    );
    const { getPopupsIds, removePopupHistory } = usePopupHistories();

    const {
        language: { urlPrefix },
    } = useLanguage();

    const modalData = useMemo(
        () =>
            new ReferencePopupRepository(
                __,
                apolloClient,
                mainAccountData,
                urlPrefix,
            ),
        [__, apolloClient, mainAccountData, urlPrefix],
    );

    const currentGeoCountryCode = Array.isArray(geoCountryCode)
        ? geoCountryCode[0]
        : geoCountryCode;

    useEffect(() => {
        let cancelled: boolean | undefined;
        (async () => {
            if (
                countriesLoading ||
                currenciesLoading ||
                partnerInformationLoading ||
                partnerAccountData.loading ||
                !partnerInformationData ||
                !currenciesData
            ) {
                return [];
            }
            cancelled = false;
            const user = partnerInformationData.authorized.user;
            const loadingPopups = modalData.getLoadingPopupData(
                user,
                currenciesData,
                currentGeoCountryCode,
                countryCodes,
            );
            const responses = await Promise.all(loadingPopups);
            if (cancelled) {
                return;
            }

            const filteredResponses = responses.filter(resp => {
                if (resp instanceof BannerPopupData) {
                    return resp.data.adAgentId === user.adAgentId;
                } else if (
                    resp instanceof TelegramPopupData ||
                    resp instanceof InfoBlockPopupData
                ) {
                    return resp.props.adAgentId === user.adAgentId;
                } else {
                    return true;
                }
            });

            const storedPopupIds = getPopupsIds();
            const existingPopupsIds = filteredResponses.map(popup => popup.id);

            storedPopupIds.forEach(id => {
                if (!existingPopupsIds.includes(id)) {
                    removePopupHistory(id);
                }
            });

            setLoadedPopups(filteredResponses);
        })();

        return () => {
            if (cancelled !== undefined) {
                cancelled = true;
            }
        };
    }, [
        getPopupsIds,
        removePopupHistory,
        currenciesData,
        currenciesLoading,
        modalData,
        partnerAccountData.loading,
        partnerInformationData,
        partnerInformationLoading,
        currentGeoCountryCode,
        countriesLoading,
        countryCodes,
    ]);

    const getComponentByType = (popupData: PopupData) => {
        const components = {
            [PopupType.Unknown]: null,
            [PopupType.BlockReference]: BlockReferenceContent,
            [PopupType.Telegram]: TelegramContent,
            [PopupType.Banner]: BannerContent,
            [PopupType.ConfirmEmail]: ConfirmEmailContent,
            [PopupType.InfoBlock]: InfoBlockContent,
            [PopupType.CustomBanner]: CustomBlockContent,
        };
        return components[popupData.type];
    };

    const handleClose = useCallback(() => {
        setLoadedPopups(prev => {
            const newLoadedPopups = [...(prev || [])];
            newLoadedPopups.pop();
            return newLoadedPopups;
        });
    }, []);

    if (!partnerInformationData) {
        return null;
    }

    const popups = loadedPopups?.map(loadedPopup => (
        <NotificationPopup
            key={loadedPopup.id}
            id={loadedPopup.id}
            modalData={loadedPopup}
            partnerInformation={partnerInformationData}
            Component={getComponentByType(loadedPopup)}
            onClose={handleClose}
        />
    ));

    return popups?.at(-1) || null;
};

import styled, { css } from "styled-components";
import arrowSrc from "../../../../../assets/images/icons/arrow.svg";
import { media } from "services/styled-components/variables";
import {
    errorColor,
    accentColor,
    blockBgColor,
    secondTextColor,
    borderColor,
} from "../../../../../services/styled-components/variables";
import { IconFlag } from "./iconFlag";
import { rtl } from "services/styled-components/rtl";

interface IWithCountries {
    withCountries?: boolean;
}

interface IProps {
    active?: boolean;
    isValid?: boolean;
    selected?: boolean;
    hasHint?: boolean;
    disabled?: boolean;
}

interface IItemsWrapper {
    extended?: boolean;
    hideScroll?: boolean;
}

export const CustomSelectWrapper = styled.div<IWithCountries>`
    ${props => css`
        position: relative;
        width: 38.7rem;
        height: 5rem;
        ${props.withCountries &&
        css`
            position: absolute;
            width: auto;
            height: auto;
            top: 1.4rem;
            ${rtl`left: 5.5rem;`};
        `}

        ${media.mdMax`
                font-size: 1.4rem;
                height: 4rem;
                width: 28.8rem;
                ${
                    props.withCountries &&
                    css`
                        top: 1.1rem;
                        width: auto;
                        height: auto;
                        ${rtl`left: 4.7rem;`};
                    `
                }
        `}
    `}
`;

export const Select = styled.select`
    display: none;
`;

export const Selected = styled.div<IProps & IWithCountries>`
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.6rem;
    color: ${({ selected }) =>
        selected ? secondTextColor : "rgba(24, 24, 25, 0.5);"};
    background: ${({ disabled }) => (disabled ? "#e6edf3" : "#ffffff")};
    padding: 0 4rem;
    border-radius: 60rem;
    border: 0.1rem solid
        ${({ isValid }) => (isValid ? borderColor : errorColor)};
    display: flex;
    align-items: center;
    cursor: pointer;
    &::after {
        position: absolute;
        content: "";
        height: 2rem;
        width: 2rem;
        background-image: url("${arrowSrc}");
        background-repeat: no-repeat;
        background-position: center;
        ${rtl`right: 1.4rem;`};
        ${({ active }) => {
            return active ? "transform: rotate(180deg);" : "";
        }}
    }
    ${props =>
        props.withCountries &&
        css`
            width: 2.4rem;
            height: 2rem;
            position: relative;
            border: none;
            color: #000000;
            padding: 0;
            &::after {
                ${rtl`right: 0;`};
                height: 0.6rem;
                width: 1rem;
                background-image: url("${arrowSrc}");
                top: 0.8rem;
            }
        `}
    & ${IconFlag} {
        position: relative;
        ${rtl`left: -1.1rem;`};
    }

    ${props => css`
        ${media.mdMax`
            font-size: 1.4rem;
            padding: 0 ${props.withCountries ? "0" : "3.4rem"};
        `}
    `}
`;

export const SelectItem = styled.div`
    color: black;
    min-height: 2.5rem;
    padding: 0 3rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    cursor: pointer;
    ${media.mdMax`
        font-size: 1.2rem;
    `}
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const DropDownWrapper = styled.div<IProps & IWithCountries>`
    width: 100%;
    top: 101%;
    position: absolute;
    background: ${blockBgColor};
    border-radius: 2rem;
    padding: 1.5rem 0;
    z-index: 2;
    display: ${({ active }) => (active ? "block" : "none")};

    ${props =>
        props.withCountries &&
        css`
            width: 39rem;
            top: 3.7rem;
            left: -4.5rem;
            z-index: 1;
            ${media.mdMax`
                width: 28.8rem;
                top: 3rem;
                left: -3.5rem;
            `}
        `}
`;

export const ItemsWrapper = styled.div<IItemsWrapper>`
    max-height: ${({ extended }) => (extended ? "19.4rem" : "14.5rem")};
    width: 96%;
    overflow-y: ${({ hideScroll }) => (hideScroll ? "hide" : "scroll")};
    scrollbar-width: thin;
    ${media.mdMax`
        top: 3.1rem;
    `}
    /* stylelint-disable */
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: #ffffff;
        -webkit-border-radius: 0.2rem;
    }
    &::-webkit-scrollbar-thumb {
        background: ${borderColor};
        -webkit-border-radius: 0.2rem;
    }
`;

export const Required = styled.p`
    position: absolute;
    top: 1.2rem;
    font-size: 1.6rem;
    margin: 0;
    color: ${accentColor};
    ${rtl`left: 3rem;`};
    ${media.mdMax`
        font-size: 1.4rem;
        top: 1rem;
        ${rtl`left: 2.4rem;`};
    `}
    ${media.ltMax`
        display: none;
    `}
`;

export const FormError = styled.div`
    font-size: 1rem;
    position: absolute;
    color: ${errorColor};
    margin: 2rem;
`;

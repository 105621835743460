import {
    GetCurrentNews,
    GetCurrentNewsVariables,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import { useQuery } from "@apollo/client";
import GET_CURRENT_NEWS from "../gql/get-current.gql";

export const useCurrentNew = (code: string, newsId?: string) => {
    const queryResult = useQuery<GetCurrentNews, GetCurrentNewsVariables>(
        GET_CURRENT_NEWS,
        {
            ssr: true,
            skip: !newsId,
            variables: {
                params: {
                    culture: code,
                    newsId: newsId as string,
                },
            },
        },
    );

    const status = g(
        queryResult,
        "data",
        "partnersProgram",
        "news",
        "getCurrentNews",
        "statusID",
    );
    const currentNew = g(
        queryResult,
        "data",
        "partnersProgram",
        "news",
        "getCurrentNews",
        "news",
    );

    usePooling(queryResult, status, 500);

    return {
        status,
        currentNew,
        error: queryResult.error,
        loading: queryResult.loading,
    };
};

import styled from "styled-components";
import { CustomInput } from "../../../../../../components/custom-input";
import { media } from "services/styled-components/variables";
import { rtl } from "services/styled-components/rtl";

export const PhoneWrapper = styled.div`
    position: relative;
`;

export const PhoneInput = styled(CustomInput)`
    & input[type="text"] {
        padding-inline-start: 9rem;
        ${media.mdMax`
            padding-inline-start: 8rem;
    `}
    }
`;

interface IPlaceholder {
    lengthPhoneCode: number;
}
export const Placeholder = styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${(props: IPlaceholder) =>
        rtl`margin-left: ${props.lengthPhoneCode + 10.4}rem;`};
    color: rgba(24, 24, 25, 0.5);
    font-size: 1.6rem;
    pointer-events: none;
    ${media.mdMax`
        ${(props: IPlaceholder) =>
            rtl`margin-left: ${props.lengthPhoneCode / 1.1 + 9.2}rem;`};
    `}
`;
